import { format } from 'date-fns'

export const COLUMNS2 = [
    {
      Header: 'Name / parameters guaranteed',
      accessor: 'name',
      sticky: 'left'
    },
    {
      Header: 'Ash, max. %',
      accessor: 'data1',
    },
    {
      Header: 'Moisture, max. %',
      accessor: 'data2'
    },
    {
        Header: 'Sulphur, max. %',
        accessor: 'data3'
    },
    {
        Header: 'Phosphorus, max. %',
        accessor: 'data4'
    },
    {
        Header: 'VM, max. %',
        accessor: 'data5'
    },
    {
        Header: 'M40, min.',
        accessor: 'data6'
    },
    {
        Header: 'M10, max.',
        accessor: 'data7'
    },
    {
        Header: 'CSR, min.',
        accessor: 'data8'
    },
    {
        Header: 'CRI, max.',
        accessor: 'data9'
    },
    {
        Header: 'Fixed Carbon, min. %',
        accessor: 'data10'
    },
    {
        Header: 'Size',
        accessor: 'data11'
    }

  ]
  